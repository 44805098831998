import React, { useState } from 'react'
import ActionLink from './ActionLink'
import styles from './DevSticker.module.scss'

export default () => {
  const [visible, setVisible] = useState(true);
  return visible ? (
    <ActionLink className={styles.sticker} onClick={() => setVisible(false)} title="Click to hide">Dev</ActionLink>
  ) : null;
}
